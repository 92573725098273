














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.add_strategy_dialog_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
.main_strategy_server_table {
  .el-table .cell {
    word-break: break-word;
  }
}
